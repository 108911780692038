import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import style from './paragraph.module.css';

const Paragraph = ({ firstLiRef, children }) => {
  const [, setTime] = useState(Date.now());
  const paragraphRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000 / 30);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!firstLiRef.current || !paragraphRef.current) return;

    const firstImageWidth = parseInt(
      getComputedStyle(firstLiRef.current).width,
      10,
    );

    paragraphRef.current.style.width = `${firstImageWidth / 2}px`;
  });

  return (
    <p className={style.p} ref={paragraphRef}>
      {children}
    </p>
  );
};

Paragraph.propTypes = {
  firstLiRef: PropTypes.shape({
    current: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default Paragraph;
